import React from 'react'
import HelmetAsync from '../CommonElements/HelmetAsync';
import Breadcrumbs from '../components/Breadcrumbs';
import Introduction from '../components/About/Introduction';
import MissionAndVision from '../components/About/MissionAndVision';

export const About = () => {
    return (
        <>
            <HelmetAsync 
                title="About Us" 
                description="Learn more about Olepaipai and our mission to empower and connect communities. Discover our values, initiatives, and the impact we aim to make in Kenya and beyond." 
            />

            <Breadcrumbs title="About Olepaipai" />

            <Introduction />

            <MissionAndVision />
        </>
    )
}
export default About;