import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Constant';
import LoadingSpinner from '../../CommonElements/LoadingSpinner';
import Select from 'react-select';

const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' }
];

const religionOptions = [
    { value: 'Christianity', label: 'Christianity' },
    { value: 'Islam', label: 'Islam' },
    { value: 'Hinduism', label: 'Hinduism' },
    { value: 'Judaism', label: 'Judaism' },
    { value: 'Other', label: 'Other' }
];

const VolunteerForm = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        passport: '',
        phone: '',
        address: '',
        gender: '',
        religion: '',
        languages: [],
        area_of_interest: [],
        education: '',
        comment: ''
    });
    // const [languages, setLanguages] = useState([]);
    // const [areasOfInterest, setAreasOfInterest] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // const fetchLanguagesAndInterests = async () => {
        //     try {
        //         const [langResponse, interestResponse] = await Promise.all([
        //             axios.get(`${BASE_URL}/languages/`),
        //             axios.get(`${BASE_URL}/volunter-area-of-interest/`)
        //         ]);

        //         setLanguages(langResponse.data.map(lang => ({ label: lang.name, value: lang.id })));
        //         setAreasOfInterest(interestResponse.data.map(interest => ({ label: interest.name, value: interest.id })));
        //     } catch (error) {
        //         console.error('Failed to load options:', error);
        //         toast.error('Failed to load options.');
        //     }
        // };
        // fetchLanguagesAndInterests();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    // const handleSelectChange = (name, selectedOptions) => {
    //     const selectedArray = selectedOptions
    //         ? selectedOptions.map(option => ({ id: option.value })) // Creating an array of objects with id only
    //         : [];
            
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         [name]: selectedArray // Update formData with the array
    //     }));
    // };
    const handleSingleSelectChange = (name, selectedValue) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedValue ? selectedValue : '' // Use empty string if no selection
        }));
    };
    
    // const handleSingleSelectChange = (name, selectedOption) => {
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         [name]: selectedOption ? selectedOption.value : ''
    //     }));
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        // Check if languages and area_of_interest are empty, set them to empty arrays if they are
        const submissionData = {
            ...formData,
            languages: formData.languages.length ? formData.languages : [], // Ensures it's an array
            area_of_interest: formData.area_of_interest.length ? formData.area_of_interest : [], // Ensures it's an array
        };
    
        // console.log('Form Data Before Submission:', submissionData); // Log to check the final structure
    
        try {
            const response = await axios.post(`${BASE_URL}/volunteers/`, submissionData);
            if (response.status === 201) {
                toast.success('Thank you for your application! Our team will review your information and get back to you shortly.');
                // Reset form after successful submission
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    passport: '',
                    phone: '',
                    address: '',
                    gender: '',
                    religion: '',
                    languages: [],
                    area_of_interest: [],
                    education: '',
                    comment: ''
                });
            }
        } catch (error) {
            toast.error(error.response?.data?.detail || 'An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <>
            <h4 className="contact-title text-center">Join Us in Making a Difference!</h4>
            <form id="contact-form" onSubmit={handleSubmit} className="box-select">
                <div className="row">
                    {['first_name', 'last_name', 'email', 'passport', 'phone', 'address'].map((field, idx) => (
                        <div key={idx} className="col-md-6">
                            <input
                                type={field === 'email' ? 'email' : 'text'}
                                name={field}
                                placeholder={field.replace('_', ' ').toUpperCase()}
                                value={formData[field]}
                                onChange={handleChange}
                                required={['first_name', 'last_name', 'email', 'passport', 'phone'].includes(field)}
                            />
                        </div>
                    ))}

                    <div className="col-md-6 mb-4">
                        <div className="select arrow">
                            <select
                                name="gender"
                                value={formData.gender} // Set the value from formData
                                onChange={(e) => handleSingleSelectChange('gender', e.target.value)} // Handle change
                                required // Mark this field as required
                            >
                                <option value="" disabled>
                                    Select Gender
                                </option>
                                {genderOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 mb-4">
                        <div className="select arrow">
                            <select
                                name="religion"
                                value={formData.religion} // Set the value from formData
                                onChange={(e) => handleSingleSelectChange('religion', e.target.value)} // Handle change
                                required // Mark this field as required
                            >
                                <option value="" disabled>
                                    Select Religion
                                </option>
                                {religionOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>


                    {/* <div className="col-md-6 mb-4">
                        <Select
                            options={genderOptions}
                            name="gender"
                            onChange={(selected) => handleSingleSelectChange('gender', selected)}
                            placeholder="Select Gender"
                            isClearable
                        />
                    </div> */}
                    {/* <div className="col-md-6 mb-4">
                        <Select
                            options={religionOptions}
                            name="religion"
                            onChange={(selected) => handleSingleSelectChange('religion', selected)}
                            placeholder="Select Religion"
                            isClearable
                        />
                    </div> */}

                    {/* <div className="col-md-6 mb-4">
                        <Select
                            options={languages}
                            name="languages"
                            onChange={(selected) => handleSelectChange('languages', selected)}
                            placeholder="Select Languages"
                            isMulti
                            isClearable
                        />
                    </div> */}

                    {/* <div className="col-md-6 mb-4">
                        <Select
                            options={areasOfInterest}
                            name="area_of_interest"
                            onChange={(selected) => handleSelectChange('area_of_interest', selected)}
                            placeholder="Select Areas of Interest"
                            isMulti
                            isClearable
                        />
                    </div> */}

                    <div className="col-md-12">
                        <textarea
                            name="education"
                            placeholder="EDUCATION"
                            value={formData.education}
                            onChange={handleChange}
                            rows="3"
                        />
                    </div>
                    <div className="col-md-12">
                        <textarea
                            name="comment"
                            placeholder="Comment"
                            value={formData.comment}
                            onChange={handleChange}
                            rows="3"
                        />
                    </div>

                    <div className="col-md-12 mb-4">
                        <button type="submit" className="default-btn" disabled={loading}>
                            {loading ? <LoadingSpinner /> : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default VolunteerForm;
