import React from 'react'

const Introduction = () => {
    return (
        <>
            <section className="about-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/2.png" alt="AAbout Olepaipai" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 pt-5">
                            <div className="about-text mt-5">
                                <div className="section-title">
                                    <h3>INTRODUCTION</h3>
                                    <p>  
                                        Olepaipai invites you to embrace your next chapter and live well like never before in our well-appointed apartment community. We offer Assisted Living for residents who want to focus on the more important things in life and Memory Care for loved ones to make their every moment a positive one. Our elegantly modern community redefines retirement living with beautifully appointed living spaces, five-star services, and resort-worthy amenities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Introduction
