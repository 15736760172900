import React from 'react'

const VolunteerInfo = () => {
    return (
        <>
            <h4 className="contact-title">Join Our Volunteer Team</h4>
            <div className="contact-text">
                <p>
                    Volunteers are vital to our mission, offering support to both patients and their families. Just a few hours a week can make a significant difference in providing compassion and care.
                </p>
                <p>
                    Numerous volunteer opportunities exist, from direct patient interaction to non-patient-related tasks. Activities may include companionship, reading, light housekeeping, meal delivery, and clerical support, among others.
                </p>
                <p>
                    Your time enhances our hospice care, improving the quality of life for patients and families during their journey.
                </p>
            </div>

            <h4 className="contact-title" style={{ marginTop: '1.25rem' }}>Volunteer with Olepaipai</h4>
            <div className="contact-text">
                <p>
                    No specialized training is required. We offer free training through our volunteer program. Share your talents to provide comfort and care to those in their final days.
                </p>
            </div>
        </>
    )
}

export default VolunteerInfo
