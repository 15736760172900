import React from 'react'
import About from '../components/Home/About'
import WhoBenefits from '../components/Home/WhoBenefits'
import Sliders from '../components/Home/Sliders'


export const Home = () => {

    return (
        <>
            <Sliders />
            <About />

            <section className="advertise-area bg-3 overlay-dark">
                <div className="container">
                    <div className="row">
                    <div className="col-12">
                        <div className="advertise-text">
                            <h1 style={{ fontSize: '1.6rem' }}>
                            From hospital discharge to full recovery, we’ll be by your side, providing the physical and emotional support you need.
                            <br /><br /> Let us help you on your road to recovery
                            </h1>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <WhoBenefits />

            <section className="about-area bg-light pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>Get the care you need in the home you love.</h3>
                                    <p>              
                                    From help a few days a week to 24/7 support for more complex needs, we have an in-home care solution that’s right for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div className="section-title text-center">
                                <h3 style={{ fontSize: '0.9rem' }}>Our Services</h3>
                                <h1 style={{ fontSize: '2rem' }} className='mb-3'>
                                    Exceptional Care at Home for Older Adults
                                </h1>
                                <p>
                                    First, we get to know you and your care needs, then we design a flexible Care Plan just for you.<br />
                                    You’ll get the in-home care solution you need—and always be informed and in control.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing">
                                <div className="package-name">
                                    <h3>Home Care</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        From a few hours a day of help to 24/7 support, we will design a Care Plan to meet your specific needs. Our high-quality in-home care includes:
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Companionship</p></span>
                                    <span><p>Help At Home</p></span>
                                    <span><p>Respite Care</p></span>
                                    <span><p>24/7 Care</p></span>
                                    <span><p>Live-In Care</p></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing best-offer">
                                <div className="package-name">
                                    <h3>Memory Care at Home*</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        Our caregivers are expertly trained to support people living with any type of cognitive decline from mild memory problems to advanced dementia.
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Mild Cognitive Impairment (MCI)</p></span>
                                    <span><p>Alzheimer’s Disease</p></span>
                                    <span><p>Vascular Dementia</p></span>
                                    <span><p>Lewy Body Dementia</p></span>
                                    <span><p>Frontotemporal Dementia</p></span>
                                    <span><p>Parkinson’s Disease Dementia</p></span>
                                    <p className='p-2'>
                                        * Personal care and non-medical support for people living with cognitive impairment
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing">
                                <div className="package-name">
                                    <h3>Specialized Care</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        We support people living with specific medical conditions and unique needs, providing the best care so our clients can live safely at home.
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Parkinson’s Disease</p></span>
                                    <span><p>Heart Health</p></span>
                                    <span><p>After A Stroke</p></span>
                                    <span><p>Cancer</p></span>
                                    <span><p>End-Of-Life Care</p></span>
                                    <span><p>Hospital To Home</p></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-btn pt-5 text-center">
                        <a className="default-btn" href="/our-services">OUR SERVICES</a>
                    </div>
                </div>
            </section>


            <section className="advertise-area bg-3 overlay-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="advertise-text">
                                <h1 style={{ fontSize: '1.6rem' }}>Our Hospital-to-Home care solution sets you up to recover safely and comfortably at home.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="event-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="section-title text-center">
                                <h3>Photos of Olepaipai</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row event-items">
                    <div className="single-event col-md-8 col-12 conference">
                            <div className="single-event-item">
                                <img src="assets/img/galery/1.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-4 small col-12 music">
                            <div className="single-event-item">
                                <img src="assets/img/galery/3.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-4 small col-12 birthday">
                            <div className="single-event-item">
                                <img src="assets/img/galery/4.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-8 col-12 wedding">
                            <div className="single-event-item">
                                <img src="assets/img/galery/2.png" alt="Olepaipai" />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="header-links  mb-5">
                            <div className="banner-btn text-center">
                                <a className="default-btn" href="/photos">View More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}
export default Home;