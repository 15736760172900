import React from 'react'

const Sliders = () => {
    return (
        <>
            <section className="slider-area">
                <div className="slider-wrapper">
                    <div className="single-slide" style={{ backgroundImage: "url('assets/img/slider/4.png')" }}>
                        <div className="banner-content overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 mx-auto">
                                        <div className="text-content-wrapper">
                                            <div className="text-content text-center">
                                                <h1 style={{ color: '#0F53A5'}}>Welcome to Olepaipai</h1>
                                                {/* <p style={{ color: '#fff'}}>
                                                    Discover a world of care and compassion at Olepaipai. Our dedicated team is here to support you and your loved ones with personalized services tailored to your needs. Experience comfort, safety, and a sense of community.
                                                </p>

                                                <div className="banner-btn">
                                                    <a className="default-btn" href="/our-services">explore</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single-slide" style={{ backgroundImage: "url('assets/img/slider/4.png')" }}>
                        <div className="banner-content overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-10 mx-auto">
                                        <div className="text-content-wrapper slide-two">
                                            <div className="text-content text-center">
                                                <h1 className="text-white" style={{ color: '#0F53A5'}}>Welcome to Olepaipai</h1>
                                                <p style={{ color: '#5DBB43'}}>
                                                    Discover a world of care and compassion at Olepaipai. Our dedicated team is here to support you and your loved ones with personalized services tailored to your needs. Experience comfort, safety, and a sense of community.
                                                </p>

                                                <div className="banner-btn">
                                                    <a className="default-btn" href="/our-services">explore</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sliders
