import React from 'react'
import HelmetAsync from '../CommonElements/HelmetAsync';
import Breadcrumbs from '../components/Breadcrumbs';

export const OurApproach = () => {
    return (
        <>
            <HelmetAsync 
                title="Our Approach" 
                description="At Olepaipai, we believe in a collaborative and inclusive approach to community development. Our strategies focus on empowerment, sustainability, and active participation, ensuring that every voice is heard and every contribution valued. Join us as we work together to create positive change." 
            />

            <Breadcrumbs title="Our Approach" />

            <section className="about-area pb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/2.png" alt="AAbout Olepaipai" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>The Philosophy Behind the Method </h3>
                                    <div className="room-facilities-2">
                                        <p>We believe that everyone has the potential to improve their wellness and quality of life—at every stage.</p>
                                        <div className="single-facility-2">
                                            <span><i className="zmdi zmdi-check"></i>We meet clients where they are, getting to know them as people not patients</span>
                                            <span><i className="zmdi zmdi-check"></i>In every interaction, we’re guided by our person-centered approach, focusing on our client’s wellness not their condition.</span>
                                            <span><i className="zmdi zmdi-check"></i>We choose the best activities for each client’s capabilities, interests, and passions.</span>
                                            <span><i className="zmdi zmdi-check"></i>Our mission is to create joy and purpose for our clients every day while supporting their independence.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="about-area bg-light pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>Expert, personalized care brings joy to life.</h3>
                                    <p>              
                                    Tailoring the components of our Balanced Care Method to every individual isn’t simple or quick—but it is worth it. Each caregiver-client relationship is supported by a team of experts working together to bring whole care to the whole person. Care Teams include a Client Care Manager, a Staffing Manager, and professional caregivers who are thoroughly trained in the Balanced Care Method.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>Who benefits from our unique approach?</h3>
                                    <p>
                                    Every older adult can benefit from our 360-degree approach to care. Even clients who only require a little help can blossom through balanced care that focuses on total wellness. The Balanced Care Method informs every Care Plan and level of service we provide, including:
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-text">
                                <div className="section-title">
                                    <div className="room-facilities">
                                        <div className="single-facility">
                                            <span><i className="zmdi zmdi-check"></i>Respite Care</span>
                                            <span><i className="zmdi zmdi-check"></i>Alzheimer’s Disease and Dementia</span>
                                            <span><i className="zmdi zmdi-check"></i>Heart Health</span>
                                            <span><i className="zmdi zmdi-check"></i>Cancer</span>
                                            <span><i className="zmdi zmdi-check"></i>End-of-Life Care</span>
                                        </div>
                                        <div className="single-facility">
                                            <span><i className="zmdi zmdi-check"></i>24/7 Care</span>
                                            <span><i className="zmdi zmdi-check"></i>Parkinson’s Disease</span>
                                            <span><i className="zmdi zmdi-check"></i>Care Following a Stroke</span>
                                            <span><i className="zmdi zmdi-check"></i>Hospital to Home</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="advertise-area bg-3 overlay-dark pt-5 pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-8">
                        <div className="advertise-text">
                            <h2  className='text-white'>
                                We’re passionate about helping older adults live in their own home as they age, improving the quality of life for our clients—and those who love them.
                            </h2>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="about-area pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3 className='text-center'>See the Balanced Care Method in Action</h3>
                                    <p>
                                    Care Plans are personalized based on a client’s abilities and interests, but they share one thing in common—they’re designed to nurture joy and wellness in every individual. Typical activities that engage and inspire pleasure or purpose include:
                                    </p>
                                    <div className="room-facilities">
                                        <div className="single-facility">
                                            <span><i className="zmdi zmdi-check"></i>Listening to music</span>
                                            <span><i className="zmdi zmdi-check"></i>Making holiday or birthday cards</span>
                                            <span><i className="zmdi zmdi-check"></i>Exploring the neighborhood</span>
                                            <span><i className="zmdi zmdi-check"></i>Enjoying afternoon tea</span>
                                            <span><i className="zmdi zmdi-check"></i>Getting a manicure or pedicure</span>
                                        </div>
                                        <div className="single-facility">
                                            <span><i className="zmdi zmdi-check"></i>Sorting household items by category like color or size</span>
                                            <span><i className="zmdi zmdi-check"></i>Looking at photos</span>
                                            <span><i className="zmdi zmdi-check"></i>Arts and crafts projects</span>
                                            <span><i className="zmdi zmdi-check"></i>Planting seeds in pots or the garden</span>
                                            <span><i className="zmdi zmdi-check"></i>Reminiscing about important inventions or events</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default OurApproach;