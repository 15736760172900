import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs';

export const AssistedCare = () => {
    return (
        <>
            <Breadcrumbs title="ASSISTED CARE" />

            <section className="about-area pb-5">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/Assisted-care.png" alt="ASSISTED CARE | Olepaipai" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 pt-5">
                            <div className="about-text">
                                <div className="section-title pt-5">
                                    <h3>ASSISTED CARE</h3>
                                    <p>              
                                        Our Assisted Living residents embrace life to the fullest with a full slate of engaging activities and fun events designed to appeal to a wide range of preferences and abilities. At Olepaipai, we happily defy the "old" stereotypes with energizing fitness classes, social get-togethers, and regular outings to take part in community performances and events.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AssistedCare;