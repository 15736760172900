import React from 'react'

const DonateInfo = () => {
    return (
        <>
            <h4 className="contact-title">How to Apply for Support:</h4>
            <div className="contact-text">
                <p>
                    Applications are accepted from hospice social workers, nurses, and directors on behalf of patients. Each application must include an invoice or statement from the service provider. Note that the BrightSpring Hospice Foundation pays only service providers directly and does not reimburse individuals or cover prior payments.
                </p>
                <p>
                    All applications will be carefully reviewed by our board of directors to ensure they meet the organization's guidelines.
                </p>
            </div>

            <h4 className="contact-title" style={{ marginTop: '1.25rem' }}>Donate to Olepaipai</h4>
            <div className="contact-text">
                <p>
                    When you donate to Olepaipai, your gift helps improve the quality of life for eligible hospice patients in your community.
                </p>
            </div>
            <h4 className="contact-title" style={{ marginTop: '1.25rem' }}>Donate by Mail:</h4>
            <div className="contact-text">
                <p><span className="c-icon"><i className="zmdi zmdi-phone"></i></span><span className="c-text">Phone: +254103333332 / +254783333332</span></p>
                <p><span className="c-icon"><i className="zmdi zmdi-email"></i></span><span className="c-text">Email: info@olepaipai.org</span></p>
                <p><span className="c-icon"><i className="zmdi zmdi-pin"></i></span><span className="c-text">Address: Daima Towers, 14th Floor, Uganda Road, Eldoret</span></p>
            </div>

        </>
    )
}

export default DonateInfo
