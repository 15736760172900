import React from 'react';
import HelmetAsync from '../CommonElements/HelmetAsync';
import Breadcrumbs from '../components/Breadcrumbs';

export const Photo = () => {
    return (
        <>
            <HelmetAsync 
                title="Photo Gallery" 
                description="Explore our Photo Gallery at Olepaipai! Discover the vibrant moments captured during our events, community activities, and volunteer efforts. Each photo tells a story of dedication, teamwork, and the impact we make together." 
            />

            <Breadcrumbs title="Photos & Virtual Tour " />

            <section className="event-area pt-90">
                <div className="container">
                    <div className="row event-items">
                        <div className="single-event col-md-8 col-sm-12 col-12 conference">
                            <div className="single-event-item">
                                <img src="assets/img/galery/1.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-4 col-sm-12 col-12 small music">
                            <div className="single-event-item">
                                <img src="assets/img/galery/3.png" alt="Olepaipai" />
                            </div>
                        </div>

                        <div className="single-event col-md-4 col-sm-12 col-12 small birthday">
                            <div className="single-event-item">
                                <img src="assets/img/galery/4.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-8 col-sm-12 col-12 wedding">
                            <div className="single-event-item">
                                <img src="assets/img/galery/2.png" alt="Olepaipai" />
                            </div>
                        </div>

                        <div className="single-event col-md-8 col-sm-12 col-12 conference">
                            <div className="single-event-item">
                                <img src="assets/img/galery/5.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-4 col-sm-12 col-12 small music mb-2">
                            <div className="single-event-item">
                                <img src="assets/img/galery/7.png" alt="Olepaipai" />
                            </div>
                        </div>

                        <div className="single-event col-md-4 col-sm-12 col-12 small birthday">
                            <div className="single-event-item">
                                <img src="assets/img/galery/6.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-8 col-sm-12 col-12 wedding">
                            <div className="single-event-item">
                                <img src="assets/img/galery/8.png" alt="Olepaipai" />
                            </div>
                        </div>

                        <div className="single-event col-md-8 col-sm-12 col-12 conference">
                            <div className="single-event-item">
                                <img src="assets/img/galery/14.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-4 col-sm-12 col-12 small music mb-2">
                            <div className="single-event-item">
                                <img src="assets/img/galery/17.png" alt="Olepaipai" />
                            </div>
                        </div>

                        <div className="single-event col-md-4 col-sm-12 col-12 small birthday">
                            <div className="single-event-item">
                                <img src="assets/img/galery/9.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-8 col-sm-12 col-12 wedding">
                            <div className="single-event-item">
                                <img src="assets/img/galery/15.png" alt="Olepaipai" />
                            </div>
                        </div>

                        <div className="single-event col-md-8 col-sm-12 col-12 conference">
                            <div className="single-event-item">
                                <img src="assets/img/galery/16.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-4 col-sm-12 col-12 small music mb-2">
                            <div className="single-event-item">
                                <img src="assets/img/galery/10.png" alt="Olepaipai" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Photo;
