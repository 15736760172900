import React from 'react'

const WhoBenefits = () => {
  return (
        <>
            <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>Who benefits from our unique approach?</h3>
                                    <p>
                                    Every older adult can benefit from our 360-degree approach to care. Even clients who only require a little help can blossom through balanced care that focuses on total wellness. The Balanced Care Method informs every Care Plan and level of service we provide, including:
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-text">
                                <div className="section-title">
                                    <div className="room-facilities">
                                        <div className="single-facility">
                                            <span><i className="zmdi zmdi-check"></i>Respite Care</span>
                                            <span><i className="zmdi zmdi-check"></i>Alzheimer’s Disease and Dementia</span>
                                            <span><i className="zmdi zmdi-check"></i>Heart Health</span>
                                            <span><i className="zmdi zmdi-check"></i>Cancer</span>
                                            <span><i className="zmdi zmdi-check"></i>End-of-Life Care</span>
                                        </div>
                                        <div className="single-facility">
                                            <span><i className="zmdi zmdi-check"></i>24/7 Care</span>
                                            <span><i className="zmdi zmdi-check"></i>Parkinson’s Disease</span>
                                            <span><i className="zmdi zmdi-check"></i>Care Following a Stroke</span>
                                            <span><i className="zmdi zmdi-check"></i>Hospital to Home</span>
                                        </div>
                                    </div>
                                    <div className="banner-btn pt-2 pb-3">
                                        <a className="default-btn" href="/about/our-approach">OUR APPROACH</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}

export default WhoBenefits
