import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import { LOADING } from '../../Constant';

const index = () => {
    return (
        <>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                data-testid="loading-spinner"
            />
            <span className="ml-2">{LOADING}</span>
        </>
    )
}

export default index;
