import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BASE_URL } from '../../Constant';
import LoadingSpinner from '../../CommonElements/LoadingSpinner';
import axios from 'axios';
import { toast } from 'react-toastify';

const DonateForm = () => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            phone_number: '',
            amount: '',
            selectedAmount: '',
            full_name: '',
            email: '',
            physicalAddress: '',
        },
        validationSchema: Yup.object({
            phone_number: Yup.string()
                .matches(/^254\d{9}$/, 'Phone number must be 12 digits in the correct format')
                .required('Phone number is required'),
            amount: Yup.number()
                .positive('Amount must be positive')
                .required('Amount is required when no predefined amount is selected'),
            selectedAmount: Yup.string().nullable(),
            full_name: Yup.string().required('Full name is required'),
            email: Yup.string().email('Invalid email format').required('Email is required'),
            physicalAddress: Yup.string(), // Optional field
        }),      
        onSubmit: async (values, { resetForm }) => {
            // Handle form submission
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}/donate/`, values);
                if (response.status === 201) {
                    toast.success('You will receive a popup from Safaricom to proceed or enter your PIN.');
                    resetForm(); // Reset the form fields
                }
            } catch (error) {
                toast.error('An error has occurred. Try Again.');
                console.error("Submission error:", error);
            } finally {
                setLoading(false);
            }
        },
    });

    // Function to handle selecting a predefined amount
    const handlePredefinedAmount = (amount) => {
        formik.setFieldValue('selectedAmount', amount);
        formik.setFieldValue('amount', amount);
    };

    return (
        <div className="container">
            <h2 className="text-center">Donate</h2>
            <form  id="contact-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="full_name">Full Name:</label>
                            <input
                                id="full_name"
                                name="full_name"
                                type="text"
                                className="form-control"
                                placeholder="Enter your full name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.full_name}
                            />
                            {formik.touched.full_name && formik.errors.full_name ? (
                                <div style={{ color: 'red' }}>{formik.errors.full_name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Enter your email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div style={{ color: 'red' }}>{formik.errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="physicalAddress">Physical Address:</label>
                            <input
                                id="physicalAddress"
                                name="physicalAddress"
                                type="text"
                                className="form-control"
                                placeholder="Enter your physical address"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.physicalAddress}
                            />
                            {formik.touched.physicalAddress && formik.errors.physicalAddress ? (
                                <div style={{ color: 'red' }}>{formik.errors.physicalAddress}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="phone_number">Phone Number (without 254):</label>
                            <input
                                id="phone_number"
                                name="phone_number"
                                type="text"
                                className="form-control"
                                placeholder="e.g. 254701234567"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone_number || '254'} // Default to "254" if empty
                            />
                            {formik.touched.phone_number && formik.errors.phone_number ? (
                                <div style={{ color: 'red' }}>{formik.errors.phone_number}</div>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="amount">Custom Amount (KES):</label>
                            <input
                                id="amount"
                                name="amount"
                                type="number"
                                className="form-control"
                                placeholder="Enter your amount"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.amount}
                            />
                            {formik.touched.amount && formik.errors.amount ? (
                                <div style={{ color: 'red' }}>{formik.errors.amount}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-12 mb-5">
                        <div className="form-group">
                            <label>Select a Predefined Amount (Optional):</label>
                            <div className="btn-group btn-group-toggle d-flex flex-wrap" data-toggle="buttons">
                                {['100', '250', '5000', '1000', '2500'].map((amount) => (
                                    <label className="btn btn-outline-primary m-1" key={amount}>
                                        <input
                                            type="radio"
                                            name="selectedAmount"
                                            value={amount}
                                            onChange={() => handlePredefinedAmount(amount)}
                                            checked={formik.values.selectedAmount === amount}
                                        />
                                        {amount} KES
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-4 mt-5">
                        <button className="default-btn" type="submit" disabled={loading}>
                            {loading ? <LoadingSpinner /> : 'Donate'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DonateForm;
