import React from 'react'

const MissionAndVision = () => {
    return (
        <>
            <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>OUR MISSION</h3>
                                    <p>              
                                        People matter most in life. and our purpose is to make life's journey wonderful. We are engaged in providing an inspirational place for our residents while creating a spectacular living experience that is safe. healthy. and happy- one that truly fosters purpose and dignity.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>OUR VISION</h3>
                                    <p>  
                                        Our vision is to be the community's finest care provider by giving our residents the best living experience and providing their loved ones' full peace-of-mind.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MissionAndVision
