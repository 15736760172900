import React from 'react'
import HelmetAsync from '../CommonElements/HelmetAsync';
import Breadcrumbs from '../components/Breadcrumbs';
import DonateForm from '../components/Donate/DonateForm';
import DonateInfo from '../components/Donate/DonateInfo';

const Donation = () => {
    return (
        <>
            <HelmetAsync 
                title="Donate" 
                description="Support Olepaipai's mission by making a donation today. Your contributions help us provide essential services and resources to those in need, empowering communities and fostering positive change. Join us in making a difference—every donation counts!" 
            />
            <Breadcrumbs title="Donation" />
            <section className="contact-form-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <DonateInfo />
                        </div>
                        <div className="col-md-8">
                            <DonateForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Donation
