import React from 'react';
import HelmetAsync from '../CommonElements/HelmetAsync';
import Breadcrumbs from '../components/Breadcrumbs';
import ContactInfo from '../components/Contact/ContactInfo';
import ContactForm from '../components/Contact/ContactForm';

export const Contact = () => {
    return (
        <>   
            <HelmetAsync 
                title="Contact" 
                description="Connect with us at Olepaipai. We’re here to answer your questions, provide information, and help you engage with our community. Contact us today to learn more!" 
            />
            <Breadcrumbs title="Contact Us" />
            
            <section className="contact-form-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <ContactInfo />
                        </div>
                        <div className="col-md-8">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
            <br /><br />

            {/* <div className="google-map-area">
                <div className="google-map-area w-100">
                    <iframe
                        className="contact-map"
                        title="Company Location Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2136.986005919501!2d-73.9685579655238!3d40.75862446708152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e4a1c884e5%3A0x24fe1071086b36d5!2sThe%20Atrium!5e0!3m2!1sen!2sbd!4v1585132512970!5m2!1sen!2sbd"
                        allowFullScreen
                        loading="lazy"
                    ></iframe>
                </div>
            </div> */}
        </>
    );
};

export default Contact;
