import React from 'react'
import HelmetAsync from '../CommonElements/HelmetAsync';
import Breadcrumbs from '../components/Breadcrumbs';
import VolunteerForm from '../components/Volunteer/VolunteerForm';
import VolunteerInfo from '../components/Volunteer/VolunteerInfo';

const Volunteer = () => {
    return (
        <>
            <HelmetAsync 
                title="Our Volunteers" 
                description="Join our dedicated team of volunteers at Olepaipai! Learn about the incredible individuals who contribute their time and skills to make a difference in our community. Discover how you can get involved and help us drive positive change." 
            />

            <Breadcrumbs title="Volunteer" />
            <section className="contact-form-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <VolunteerInfo />
                        </div>
                        <div className="col-md-8">
                            <VolunteerForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Volunteer
