import React, { useState } from 'react';
import './Header.css'; // Assuming you put the CSS in a separate file named Header.css
import { Link } from 'react-router-dom';

export const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [expandedMenu, setExpandedMenu] = useState(null);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleSubMenu = (menu) => {
        setExpandedMenu(expandedMenu === menu ? null : menu);
    };

    return (
        <>
            <header className="header-area fixed header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-4 col-sm-4 col-12">
                            <div className="logo">
                                <a href="/"><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} style={{ marginLeft: '-33px', marginTop: '-25px' }} /></a>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-8 col-sm-8 col-12">
                            <div className="header-top fix">
                                <div className="header-contact">
                                    <span className="text-theme">Contact:</span>
                                    <span>+254103333332/ +254783333332</span>
                                </div>
                                <div className="header-links">
                                    <a href="#"><i className="zmdi zmdi-facebook"></i ></a>
                                    <a href="#"><i className="zmdi zmdi-twitter"></i></a>
                                    <a href="#"><i className="zmdi zmdi-google-plus"></i></a>
                                    <a href="#"><i className="zmdi zmdi-instagram"></i></a>
                                    <a href="#"><i className="zmdi zmdi-pinterest"></i></a>
                                </div>
                            </div>
                            <div className="main-menu d-none d-lg-block">
                                <nav>
                                    <ul>
                                        <li><a href="/">HOME</a></li>
                                        <li>
                                            <Link to="#">ABOUT US</Link>
                                            <ul className="submenu">
                                                <li><a href="/about">ABOUT OLEPAIPAI</a></li>
                                                <li><a href="/about/our-approach">OUR APPROACH</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="/our-services">SERVICES</a></li>
                                        <li><a href="/photos">PHOTO</a></li>
                                        <li><a href="/volunteer">VOLUNTEER</a></li>
                                        <li>
                                            <a href="/contact">CONTACT US</a>
                                        </li>
                                        <li>
                                            <a href="/donate">DONATE</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-menu-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-menu">
                                    <div className="mobile-menu-header">
                                        <span className="mobile-menu-text">MENU</span>
                                        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                                            <i className={isMobileMenuOpen ? 'zmdi zmdi-close' : 'zmdi zmdi-menu'}></i>
                                        </div>
                                    </div>
                                    {isMobileMenuOpen && (
                                        <nav id="dropdown">
                                            <ul>
                                                <li><a href="/">HOME</a></li>
                                                <li>
                                                    <Link to="#" onClick={() => toggleSubMenu('about')}>ABOUT US</Link>
                                                    {expandedMenu === 'about' && (
                                                        <ul className="submenu">
                                                            <li><a href="/about">ABOUT OLEPAIPAI</a></li>
                                                            <li><a href="/about/our-approach">OUR APPROACH</a></li>
                                                        </ul>
                                                    )}
                                                </li>
                                                <li><a href="/our-services">SERVICES</a></li>
                                                <li><a href="/photos">PHOTO</a></li>
                                                <li><a href="/volunteer">VOLUNTEER</a></li>
                                                <li>
                                                    <a href="/contact">CONTACT US</a>
                                                </li>
                                                <li>
                                                    <a href="/donate">DONATE</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
