import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const index = ({ title, description }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{title} | Olepaipai</title>
                {description && <meta name="description" content={description} />}
            </Helmet>
        </HelmetProvider>
    );
};

export default index;
