import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Constant';
import LoadingSpinner from '../../CommonElements/LoadingSpinner';


export const ContactForm = () => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            message: '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            email: Yup.string().email('Invalid email format').required('Email is required'),
            phone_number: Yup.string().required('Phone number is required'),
            message: Yup.string().required('Message is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}/contact/`, values);
                if (response.status === 201) {
                    toast.success('Your message has been sent successfully!');
                    resetForm();
                }
            } catch (error) {
                toast.error('An error has occurred. Try Again.');
                console.error("Submission error:", error);
            } finally {
                setLoading(false);
            }
            setLoading(false);
        },
    });

    return (
        <>
            <h4 className="contact-title">send your message</h4>
            <form id="contact-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="first_name">First Name:</label>
                            <input
                                type="text"
                                name="first_name"
                                placeholder="First Name"
                                value={formik.values.first_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                            />
                            {formik.touched.first_name && formik.errors.first_name && (
                                <div style={{ color: 'red' }}>{formik.errors.first_name}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="last_name">Last Name:</label>
                            <input
                                type="text"
                                name="last_name"
                                placeholder="Last Name"
                                value={formik.values.last_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                            />
                            {formik.touched.last_name && formik.errors.last_name && (
                                <div style={{ color: 'red' }}>{formik.errors.last_name}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div style={{ color: 'red' }}>{formik.errors.email}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="phone_number">Phone Number:</label>
                            <input
                                type="text"
                                name="phone_number"
                                placeholder="Phone Number"
                                value={formik.values.phone_number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                            />
                            {formik.touched.phone_number && formik.errors.phone_number && (
                                <div style={{ color: 'red' }}>{formik.errors.phone_number}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea
                                name="message"
                                cols="30"
                                rows="10"
                                placeholder="Message here"
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                            />
                            {formik.touched.message && formik.errors.message && (
                                <div style={{ color: 'red' }}>{formik.errors.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button className="default-btn" type="submit" disabled={loading}>
                            {loading ? <LoadingSpinner /> : 'Send'}
                        </button>
                    </div>
                </div>
            </form>
        </>         
    );
};
export default ContactForm;
