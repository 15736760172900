import React from 'react'

const About = () => {
    return (
        <>
            <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/2.png" alt="About Olepaipai" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 pt-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>About Olepaipai</h3>
                                    <p>              
                                        Welcome to Olepaipai, where we redefine retirement living with luxury, comfort, and care. Our community offers Assisted Living and Memory Care services designed to enhance the quality of life for our residents. We provide personalized assistance, wellness visits, and comprehensive care to ensure our residents' well-being. Our beautifully appointed apartments, diverse dining options, and resort-worthy amenities create a vibrant and enriching environment. Join us at Olepaipai and embrace a new chapter of life with purpose, dignity, and joy.
                                    </p>
                                    <div className="banner-btn mb-5">
                                        <a className="default-btn" href="/about">ABOUT OLEPAIPAI</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About
