import React from 'react'

export const index = ({ title, description }) => {
    return (
        <>
            <section className="breadcrumb-area overlay-dark-2 bg-3">	
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-text text-center">
                                <h2>{title}</h2>
                                <p>{description && description }</p>
                                <div className="breadcrumb-bar">
                                    <ul className="breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>{title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
		</section>
        </>
    )
}
export default index;