import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const Footer = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.currentTarget;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('http://127.0.0.1:8000/landing/contact/', formData);
            if (response.status === 201) {
                toast.success('You have subscribed successfully!');
                clearForm();
            }
        } catch (error) {
            toast.error('An error has occurred. Try Again.');
        }
        setLoading(false);
    };

    const clearForm = () => {
        setFormData({
            email: '',
            name: ''
        });
    };
    return (
        <>
           
            <section className="newsletter-area bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 mx-auto col-12">
                            <div className="newsletter-container">
                                <h3>NewsLetter Sign-Up</h3>
                                <div className="newsletter-form">
                                    <form className="mc-form fix" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-5 pb-3">
                                                <input type="text" name="name" value={formData.name} onChange={handleChange} required placeholder="Enter your Name"/>
                                            </div>
                                            <div className="col-md-5 pb-3">
                                                <input type="email" name="email" value={formData.email} onChange={handleChange} required placeholder="Enter your E-mail"/>
                                            </div>
                                            <div className="col-md-2 pb-3">
                                                <button className="default-btn" type="submit" disabled={loading}>
                                                    {loading ? 'Sending...' : 'Subscribe'}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer-area">
                <div className="footer-widget-area bg-dark">
                    <div className="container">
                        <div className="row mb-n60">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
                                <div className="single-footer-widget">
                                    <div className="footer-logo">
                                        <a href="/">
                                            <img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} style={{ marginLeft: '-10px', marginTop: '-25px' }}/>
                                        </a>
                                        </div>
                                        <p>
                                            Welcome to Olepaipai, where we redefine retirement living with luxury, comfort, and care. Our community offers Assisted Living and Memory Care services designed to enhance the quality of life for our residents. We provide personalized assistance, wellness visits, and comprehensive care to ensure our residents' well-being.
                                        </p>
                                        <div className="social-icons">
                                        <a href="#/"><i className="zmdi zmdi-facebook"></i></a>
                                        <a href="#/"><i className="zmdi zmdi-instagram"></i></a>
                                        <a href="#/"><i className="zmdi zmdi-rss"></i></a>
                                        <a href="#/"><i className="zmdi zmdi-twitter"></i></a>
                                        <a href="#/"><i className="zmdi zmdi-pinterest"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
                                <div className="single-footer-widget">
                                    <h3>contact us</h3>
                                    <div className="c-info">
                                        <span><i className="zmdi zmdi-pin"></i></span>
                                        <span>Daima Towers, 14TH Flr.  <br />Uganda Road, Eldoret</span>
                                    </div>
                                    <div className="c-info">
                                        <span><i className="zmdi zmdi-email"></i></span>
                                        <span>info@olepaipai.org<br /><br /></span>
                                    </div>
                                    <div className="c-info">
                                        <span><i className="zmdi zmdi-phone"></i></span>
                                        <span>+254103333332<br />+254783333332</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
                                <div className="single-footer-widget">
                                    <h3>quick links</h3>
                                    <ul className="footer-list">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/about">About Olepaipai</a></li>
                                        <li><a href="/about/our-approach">Our Approach</a></li>
                                        <li><a href="/our-services">Our Services</a></li>
                                        <li><a href="/photos">Photo & Virtual Tour</a></li>
                                        <li><a href="/contact">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area bg-black">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <div className="footer-text text-center">
                                    <span>Copyright © 2024 <b className="text-white">Olepaipai</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;